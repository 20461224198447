<template>
  <div>
    <el-table :data="tableData" class="w-100">
      <el-table-column
        sortable
        prop="name"
        label="Name"
        width="180"
      ></el-table-column>
      <el-table-column
        sortable
        prop="title"
        label="Title"
        width="180"
      ></el-table-column>
      <el-table-column
        sortable
        prop="address"
        label="Address"
      ></el-table-column>
      <el-table-column sortable label="Operations">
        <template slot-scope="scope">
          <el-button-group>
            <el-button
              icon="el-icon-edit"
              @click="update(scope.row, scope.$index)"
            ></el-button>
            <el-button
              icon="el-icon-delete"
              @click="remove(scope.$index)"
            ></el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>

    <el-drawer
      title="Shareholder"
      :visible.sync="component.drawer"
      direction="rtl"
      size="500px"
    >
      <div class="pt-3 pl-5 pr-5 pb-5">
        <component-shareholder
          v-on:refresh="load($event)"
          :data="component.data"
          :key="component.render"
        />
      </div>
    </el-drawer>
    <el-button class="fixed-bottom new-register" type="success" @click="add"
      >Add new register</el-button
    >
  </div>
</template>

<script>
import shareholder from "@/services/api/shareholder";

import ComponentShareholder from "@/components/admin/client/Shareholder";

export default {
  components: {
    ComponentShareholder,
  },
  data() {
    return {
      tableData: [],
      component: {
        data: null,
        drawer: false,
        render: 0,
      },
      index: null,
    };
  },
  mounted() {
    shareholder.getById(this.$route.params.id).then((response) => {
      this.tableData = response;
    });
  },
  methods: {
    load(event) {
      this.component.drawer = false;
      //
      if (event.update) {
        this.tableData.splice(this.index, 1, event.data);
      } else {
        this.tableData.unshift(event.data);
      }
    },
    add() {
      this.component.drawer = true;
      this.component.render += 1;
      this.component.data = null;
    },
    update(row, index) {
      this.index = index;
      //
      this.component.drawer = true;
      this.component.render += 1;
      this.component.data = row;
    },
    remove(index) {
      this.tableData.splice(index, 1);
    },
  },
};
</script>

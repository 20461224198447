<template>
  <div>
    <el-form :model="form" ref="form" :hide-required-asterisk="true">
      <el-form-item
        :label="$t('credential.description')"
        prop="input"
      >
        <el-select v-model="form.input" placeholder="Select description" class="w-100">
          <el-option v-for="item in inputs" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        :label="$t('credential.link')"
        prop="link"
      >
        <el-input type="text" v-model="form.link" placeholder="Insert Link"></el-input>
      </el-form-item>

      <el-form-item
        :label="$t('credential.user')"
        prop="user"
      >
        <el-input type="text" v-model="form.user" placeholder="Insert User"></el-input>
      </el-form-item>

      <el-form-item
        :label="$t('credential.password')"
        prop="password"
      >
        <el-input type="text" v-model="form.password"></el-input>
      </el-form-item>

      <el-form-item
        :label="$t('credential.pin')"
        prop="pin"
      >
        <el-input type="text" v-model="form.pin" placeholder="Insert Pin"></el-input>
      </el-form-item>

      <el-form-item
        :label="$t('credential.other')"
        prop="other"
      >
        <el-input type="text" v-model="form.other" placeholder="Other"></el-input>
      </el-form-item>

      <el-button type="primary" @click="(!data)?submitForm('form'):updateForm('form')">Save data</el-button>
    </el-form>
  </div>
</template>

<script>
import credential from "@/services/api/credential";
import credentialInput from "@/services/api/credentialInput";

export default {
  props: ["data"],
  data() {
    return {
      inputs: [],
      form: {
        id: null,
        link: null,
        user: null,
        password: null,
        pin: null,
        other: null,
        input: null,
        corporate: this.$route.params.id
      }
    };
  },
  mounted() {
    credentialInput.get().then(response => {
      this.inputs = response;
    });
    //
    if (this.data) {
      this.form.id = this.data.id;
      this.form.link = this.data.link;
      this.form.user = this.data.user;
      this.form.password = this.data.password;
      this.form.pin = this.data.pin;
      this.form.other = this.data.other;
      this.form.input = this.data.inputId;
      this.form.corporate = this.data.corporateId;
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          credential
            .create(this.form)
            .then(response => {
              this.$emit("refresh", { data: response, update: false });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.message,
                type: "error",
                customClass: "message-error"
              });
            });
        } else {
          return false;
        }
      });
    },
    updateForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          credential
            .update(this.form)
            .then(response => {
              this.$emit("refresh", { data: response, update: true });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.message,
                type: "error",
                customClass: "message-error"
              });
            });
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<template>
  <div class="bg-white shadow-sm rounded-lg p-4">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="Shareholder" name="second">
        <component-shareholder />
      </el-tab-pane>
      <el-tab-pane label="LandLord" name="third">
        <component-landlord class="mt-3" />
      </el-tab-pane>
      <el-tab-pane label="Bank credential" name="fourth">
        <component-credential-bank />
      </el-tab-pane>
      <el-tab-pane label="Credentials" name="five">
        <component-credential />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ComponentShareholder from "@/components/admin/set/Shareholder";
import ComponentLandlord from "@/components/admin/set/Landlord";
import ComponentCredentialBank from "@/components/admin/set/CredentialBank";
import ComponentCredential from "@/components/admin/set/Credential";

export default {
  components: {
    ComponentShareholder,
    ComponentLandlord,
    ComponentCredentialBank,
    ComponentCredential
  },
  data() {
    return {
      activeName: "second"
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
};
</script>